import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../../common-service';
import { GetBusinessResponse } from '../../responses/user/get-business.response';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
@Injectable({
    providedIn: 'root'
  })

export class BusinessService extends CommonService{

    constructor(
        private httpClient: HttpClient){
            super();
        }


        public get(searchText: string): Observable<GetBusinessResponse> {

            let url = `Business/${searchText}`
        
            return this.httpClient.get<GetBusinessResponse>(environment.urlApiUser + url, this.addHeaderToken())
            .pipe(
              catchError(this.handleError)
            )
          }

}