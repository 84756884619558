import { Component, Output, HostListener, Input, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalDeleteComponent } from '../../modal-delete/modal-delete.component';
import { ConfirmBuyOrderService } from 'src/app/shared/services/API/services/purchase/confirm-buy-order.service';
import { FlagShippedBuyOrderService } from 'src/app/shared/services/API/services/purchase/flag-Shipped-buy-order.service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-button-confirm-delivery',
  templateUrl: './button-confirm-delivery.component.html',
  styleUrls: ['./button-confirm-delivery.component.css']
})
export class ButtonConfirmDeliveryComponent {

  public isLoading: boolean;
  public screenWidth: number;

constructor(private dialogRef: MatDialog,
  private confirmBuyOrderService: ConfirmBuyOrderService,
  private alertService:AlertService,){
    this.onResize();
}

@Input() idBuyOrder: number | undefined;
@Input() idBuyOrderStatus: string | undefined;
@Input() entrega: string | undefined;
@Output() refreshInfo: EventEmitter<void> = new EventEmitter<void>();

NgOninit(){
}

@HostListener('window:resize', ['$event'])
onResize(event?) {
  this.screenWidth = window.innerWidth;
}

  confirmBuyOrder(){
    this.confirmBuyOrderService.confirmDelivery(this.idBuyOrder).subscribe({
      next: (returnStruct: ReturnStruct) => {
        if (returnStruct && returnStruct.isError) {
          if (this.alertService) {
            this.alertService.show('Erro', returnStruct.errorDescription, AlertType.error);
          }
          return;
        }
        this.refreshInfo.emit();
        if (this.alertService) {
          this.alertService.show('Sucesso', 'Pedido Entregue', AlertType.success);
        }
      },
      error: (error) => {
        console.log(error);
        if (this.alertService) {
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
        this.isLoading = false;
      }
    });
  }

}


