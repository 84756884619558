import { Component, EventEmitter, Output, Inject } from '@angular/core';
import { FormsModule, UntypedFormBuilder, FormBuilder, FormGroup, Validators, AbstractControl} from '@angular/forms';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { BusinessService } from 'src/app/shared/services/API/services/user/business.service';
import { GetBusinessResponse } from 'src/app/shared/services/API/responses/user/get-business.response';
import { BusinessBranchStruct } from 'src/app/shared/services/API/structs/user/business-branch.struct';
import { WorkshopSupplierRelationshipRequest } from 'src/app/shared/services/API/requests/user/workshop-supplier-relationship.request';

@Component({
  selector: 'app-modal-patner-workshop-edit',
  templateUrl: './modal-patner-workshop-edit.component.html',
  styleUrls: ['./modal-patner-workshop-edit.component.css']
})
export class ModalPatnerWorkshopEditComponent {

  constructor(private formBuilder: FormBuilder,
    public maskService: MaskService,
    private alertService: AlertService,
    private businessService: BusinessService,
    private dialogRef: MatDialogRef<ModalPatnerWorkshopEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any){

      this.model = this.formBuilder.group({
        workshopName:['', [Validators.required]],
        // branchWorkshop: this.formBuilder.array([], [this.validatorCheckBox]),
        discount: ['', [Validators.required]],
        creditLimit: ['', [Validators.required]],
        creditLimitResetDays: ['', [Validators.required]],
      });

      if(data){
        this.model.patchValue({
          branchWorkshop: data.branchWorkshop,
          discount: data.discount,
          creditLimit: data.creditLimit,
          creditLimitResetDays: data.creditLimitResetDays,
        });
        this.workshop = true
        this.editWorkshop = true
      }else{
        this.workshop = false
        this.editWorkshop = false
      }

    }

    public FormFieldModuel: MatFormFieldModule;
    public matCheckboxModule: MatCheckboxModule;
    public getBusinessResponse: GetBusinessResponse;
    public model: FormGroup;
    public abstractControl : AbstractControl ;
    public masks: Masks;
    public hide: boolean = false;
    public searchText: any;
    public isLoading: boolean;
    public branchsWorkshop: any;
    public workshop: boolean;
    public editWorkshop: boolean;
    public idBranchWorkshop: number;
    public idBranchSupplier: number;
    public selectedBranches: number[] = [];
  
    @Output() confirmRegister = new EventEmitter<any>();

    ngOnInit(): void {
      this.masks = this.maskService.getMasks();
    }

      searchWorkshop(){
        this.searchText = this.model.get("workshopName").value
        this.businessService.get(this.searchText).subscribe({
          next: (response: GetBusinessResponse) => {
            if (response.isError) {
              this.alertService.show('Erro', response.errorDescription, AlertType.error)
              this.isLoading = false;
              return;
            }
            this.getBusinessResponse = response;
            this.branchsWorkshop = this.getBusinessResponse.listBusinessBranch.find(x => x.branches).branches
            this.isLoading = false;
            this.workshop = true          
          },
          error: (error) => {
            this.isLoading = false;
            console.log(error);
            this.alertService.show('Erro inesperado', error, AlertType.error);
          },
        });
      }
    
    checkboxChanged(item: any, isChecked: boolean) {
      if (isChecked) {
        this.selectedBranches.push(item.idBranch);
      } else {
        this.selectedBranches = this.selectedBranches.filter(
          (branchId) => branchId !== item.idBranch
        );
      }
    }

    validatorCheckBox(control: AbstractControl): { [key: string]: any } | null {
      const value = control.value;
      if (value && value.length > 0) {
        return null;
      }
      return { atLeastOneRequired: true };
    }
  
    registerProfile(){
      const workshopRequest: WorkshopSupplierRelationshipRequest = new WorkshopSupplierRelationshipRequest();
      if (this.model.valid) {
        workshopRequest.creditLimit = this.model.get("creditLimit").value;
        workshopRequest.creditLimitResetDays = this.model.get("creditLimitResetDays").value;
        workshopRequest.partDiscount = this.model.get("discount").value;
        workshopRequest.listIdBranch = this.selectedBranches;
        this.confirmRegister.emit(workshopRequest);
      }
      else{
        this.alertService.show('Erro', "Preencha corretamente todos os campos.", AlertType.error)
      }
    }

}
