<div mat-dialog-tittle class="dialog-tittle">
  <h2 class="tittle">Oficinas Parceiras</h2>
</div>

<div mat-dialog-content class="dialog-content">
  <!-- <h2>Dados Pessoais</h2> -->
  <form [formGroup]="model" class="form">
    <div class="row forms-profile">
      <div class="col-md-12" *ngIf="!editWorkshop">
        <mat-form-field appearance="outline">
          <mat-label>Pesquise o Nome da Oficina</mat-label>
          <input matInput type="text" formControlName="workshopName" (keyup.enter)="searchWorkshop()">
          <button matSuffix mat-icon-button (click)="searchWorkshop()">
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="row" *ngIf="editWorkshop">
        <mat-form-field appearance="outline">
          <mat-label>{{data.branchWorkshop}}</mat-label>
          <input matInput type="text" formControlName="branchWorkshop" disabled (keyup.enter)="searchWorkshop()">
          <button matSuffix mat-icon-button (click)="searchWorkshop()">
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div *ngIf="workshop">
        <div class="row" *ngIf="!editWorkshop">
          <div class="col-md-4">
            <mat-label>Selecionar uma ou mais lojas</mat-label>
            <div *ngFor="let branch of branchsWorkshop; let i = index">
              <mat-checkbox (change)="checkboxChanged(branch, $event.checked)">
                {{ branch.branchName }}
              </mat-checkbox>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <mat-form-field appearance="outline">
              <mat-label>Insira o Desconto (%)</mat-label>
              <input matInput type="text" formControlName="discount">
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="outline">
              <mat-label>Insira o Limite de Crédito</mat-label>
              <input matInput type="text" formControlName="creditLimit">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <mat-form-field appearance="outline">
              <mat-label>Consumo do Limite zera em (dias)</mat-label>
              <input matInput type="text" formControlName="creditLimitResetDays">
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </form>

</div>

<div mat-dialog-actions [align]="'center'">
  <button class="button-cancel" mat-raised-button mat-dialog-close="false">Cancelar</button>
  <button *ngIf="workshop" id="button-register" mat-raised-button (click)="registerProfile()">Cadastrar</button>
</div>
