<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
<div class="container-body" *ngIf="workshopSupplierRelationshipResponse != null">
  <div class="col-4">
    <button class="button-back" (click)="backPage()">
      <mat-icon class="icon-back">keyboard_arrow_left</mat-icon>
      <span>Voltar</span>
    </button>
  </div>
  <div class="row">
    <div class="white-body">
      <div class="header" *ngIf="screenWidth >= 650">
        <div>
          <h2>Oficinas Parceiras</h2>
        </div>
        <div>
          <div class="buttonUser">
            <button mat-button color="primary" id="add-cart" (click)="openNewModal(null)" type="button">
              <mat-icon class="material-symbols-outlined icon-add">add</mat-icon>
              Cadastro de Oficina Parceira
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="screenWidth < 650">
        <div>
          <h2>Oficinas Parceiras</h2>
        </div>
        <div>
          <div class="buttonUser">
            <button mat-button color="primary" id="add-cart" (click)="openEditModal(null)" type="button">
              <mat-icon class="material-symbols-outlined icon-add">add</mat-icon>
              Cadastro de Oficina Parceira
            </button>
          </div>
        </div>
      </div>
      <div class="row table-responsive" *ngIf="screenWidth >= 650">
        <table class="basic-table table-color">
          <thead class="basic-table__head">
            <tr class="truncate">
              <th>Oficina</th>
              <th>Desconto (%)</th>
              <th>Limite de Crédito</th>
              <th>Resetar Limite (Dias)</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody class="basic-table__body"
            *ngFor="let item of workshopSupplierRelationshipResponse.branchWorkshopName">
            <tr class="basic-table__body__row">
              <td class="truncate">{{item.branchName}}</td>
              <td class="truncate">{{item.partDiscount}}</td>
              <td class="truncate">{{item.creditLimit}}</td>
              <td class="truncate">{{item.creditLimitResetDays}}</td>
              <td class="truncate">
                <mat-slide-toggle 
                  [checked]="item.isActive"
                  (click)="toggleStatus(item)">
                </mat-slide-toggle>
              </td>
              <td class="truncate">
                <button mat-icon-button aria-label="Example icon button with a home icon"
                  (click)="openDialogEdit(item, true)">
                  <mat-icon>launch</mat-icon>
                </button>
                <button mat-icon-button aria-label="Example icon button with a home icon"
                  (click)="openDialogDelete(item)">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="screenWidth < 650">
        <div class="row card-spaccing"
          *ngFor="let item of workshopSupplierRelationshipResponse.workshopSupplierRelationship">
          <div class="col-sm-6 w-100">
            <div class="card">
              <div class="card-body">
                <div class="row card-title">
                  <ul class="ul">
                    <li class="title">
                      <span class="second-title">Oficina: </span>
                      <span>{{item.branchName}}</span>
                    </li>
                  </ul>
                </div>
                <div class="row">
                  <ul>
                    <li class="title">
                      <span class="second-title">Limite de Crédito: </span>
                      <span>{{item.creditLimit}}</span>
                    </li>
                  </ul>
                </div>
                <div class="row button-card">
                  <div class="button-os">
                    <div class="button" mat-dialog-actions>
                      <button style="background-color: #001F38; color: white;" mat-button type="button"
                        class="btn-primary" color="primary" (click)="openAddressModal(item, true)">
                        Editar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
