import { Component, Output, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-address-delete',
  templateUrl: './modal-address-delete.component.html',
  styleUrls: ['./modal-address-delete.component.css']
})
export class ModalAddressDeleteComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any){

  
  }

  @Output() deleteConfirm = new EventEmitter<boolean>();

  deleteProfile(){
    this.deleteConfirm.emit(true);
  }

}
