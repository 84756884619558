import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../../common-service';
import { WorkshopSupplierRelationshipRequest } from '../../requests/user/workshop-supplier-relationship.request';
import { WorkshopSupplierRelationshipResponse } from '../../responses/user/workshop-supplier-relationship.response';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
@Injectable({
    providedIn: 'root'
  })

export class WorkshopSupplierRelationshipService extends CommonService{

    constructor(
        private httpClient: HttpClient){
            super();
        }

        public getAll(): Observable<WorkshopSupplierRelationshipResponse> {

            let url = `WorkshopSupplierRelationship/GetAll`
        
            return this.httpClient.get<WorkshopSupplierRelationshipResponse>(environment.urlApiUser + url, this.addHeaderToken())
            .pipe(
              catchError(this.handleError)
            )
          }

          public Post(body: WorkshopSupplierRelationshipRequest): Observable<ReturnStruct> {

            let url = `WorkshopSupplierRelationship`
        
            return this.httpClient.post<ReturnStruct>(environment.urlApiUser + url, body, this.addHeaderToken())
            .pipe(
              catchError(this.handleError)
            )
          }
          
          public Put(body: WorkshopSupplierRelationshipRequest, idWorkshopSupplierRelationship: number): Observable<ReturnStruct> {

            let url = `WorkshopSupplierRelationship/idWorkshopSupplierRelationship/${idWorkshopSupplierRelationship}`
        
            return this.httpClient.put<ReturnStruct>(environment.urlApiUser + url, body, this.addHeaderToken())
            .pipe(
              catchError(this.handleError)
            )
          }

          public Delete(idWorkshopSupplierRelationship: number): Observable<ReturnStruct> {

            let url = `WorkshopSupplierRelationship/idWorkshopSupplierRelationship/${idWorkshopSupplierRelationship}`
        
            return this.httpClient.delete<ReturnStruct>(environment.urlApiUser + url, this.addHeaderToken())
            .pipe(
              catchError(this.handleError)
            )
          }

          public ActivatedStatus(idWorkshopSupplierRelationship: number): Observable<ReturnStruct> {

            let url = `WorkshopSupplierRelationship/activated/idWorkshopSupplierRelationship/${idWorkshopSupplierRelationship}`
        
            return this.httpClient.delete<ReturnStruct>(environment.urlApiUser + url, this.addHeaderToken())
            .pipe(
              catchError(this.handleError)
            )
          }

          public DisableStatus(idWorkshopSupplierRelationship: number): Observable<ReturnStruct> {

            let url = `WorkshopSupplierRelationship/disable/idWorkshopSupplierRelationship/${idWorkshopSupplierRelationship}`
        
            return this.httpClient.delete<ReturnStruct>(environment.urlApiUser + url, this.addHeaderToken())
            .pipe(
              catchError(this.handleError)
            )
          }

}