<div class="mat-dialog-tittle" *ngIf="!this.data.workshop">
    <h2>DELETAR USUÁRIO?</h2>
</div>
<div class="mat-dialog-tittle" *ngIf="this.data.workshop">
    <h2>DELETAR PARCERIA?</h2>
</div>

<div mat-dialog-content>
    <!-- <div class="redLigth-body">
        <p><mat-icon color="warn" class="icon-delete">warning</mat-icon>
        Esta ação não pode ser desfeita</p>
    </div> -->

    <div class="alert alert-danger d-flex align-items-center" role="alert">
        <!-- <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-triangle-fill"/></svg> -->
        <mat-icon color="warn">warning</mat-icon>
        <div class="icon-delete">
            Esta ação não pode ser desfeita
        </div>
      </div>
</div>

<div mat-dialog-actions [align]="'center'">
    <button mat-raised-button mat-dialog-close="false">Cancelar</button>
    <button mat-raised-button mat-dialog-close="true" (click)="deleteProfile()" color="warn">Deletar</button>
</div>